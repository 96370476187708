import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/Styles/animate.min.css'
import './assets/Styles/boxicons.min.css'
import './App.scss';
import './Style.scss';
import './Responsive.scss';
import { TheLayout } from './containers';

import Home from './views/pages/Home/Home';
import About from './views/pages/About/About';
import Services from './views/pages/Services/Services';
import StrategicStaffing from './views/pages/Services/StrategicStaffing';

// import BlogList from './views/pages/Blog/BlogPage';
import Industries from './views/pages/Industries/Industries';
import Career from './views/pages/Career/Career';
import Contact from './views/pages/Contact/Contact';
import BlogPage from './views/pages/Blog/BlogPage';
import PrivacyPolicy from './views/pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './views/pages/TermsConditions/TermsConditions';
import StaffAugmentation from './views/pages/Services/StaffAugmentation';
import RpoServices from './views/pages/Services/RpoServices';
import SoftwareDevelopment from './views/pages/Services/SoftwareDevelopment';
import DigitalMarketingBrandImaging from './views/pages/Services/DigitalMarketingBrandImaging';
import MobileAppDevelopment from './views/pages/Services/MobileAppDevelopment';
import ProjectManagement from './views/pages/Services/ProjectManagement';
import WebsiteDevelopment from './views/pages/Services/WebsiteDevelopment';
import HealthcareDevelopment from './views/pages/Industries/HealthcareDevelopment';
import RetailEcommerce from './views/pages/Industries/RetailEcommerce';
import HireDevelopers from './views/pages/HireDevelopers/HireDevelopers';
// import SubmitResume from './views/pages/Career/SubmitResume';
import OracleHRMS from './views/pages/Services/OracleHRMS';
import AmazonWebServices from './views/pages/Services/AmazonWebServices';
import SalesforceConsultingServices from './views/pages/Services/SalesforceConsultingServices';
import DataScienceConsulting from './views/pages/Services/DataScienceConsulting';
import Goverment from './views/pages/Industries/Goverment';
import EngineeringManufacturing from './views/pages/Industries/EngineeringManufacturing';
import BankingFinance from './views/pages/Industries/BankingFinance';
import Telecommunications from './views/pages/Industries/Telecommunications';
import MediaEntertainment from './views/pages/Industries/MediaEntertainment';
import EducationElearning from './views/pages/Industries/EducationElearning';
import TravelHospitality from './views/pages/Industries/TravelHospitality';
import NotFound from './views/pages/404/404';
import WhatisStaffAugmentationWhatAreItsBenefits from './views/pages/Blog/WhatisStaffAugmentationWhatAreItsBenefits';
import TheBenefitsImplementingSalesforceInYourBusiness from './views/pages/Blog/TheBenefitsImplementingSalesforceInYourBusiness';
import TipsAndTricksToDevelopAProfessionalWebsite from './views/pages/Blog/TipsAndTricksToDevelopAProfessionalWebsite';
import KeyStepsInMobileAppDevelopmentFromIdeaToLaunch from './views/pages/Blog/KeyStepsInMobileAppDevelopmentFromIdeaToLaunch';
import SalesforceTraining from './views/pages/SalesforceTraining/SalesforceTraining';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TheLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/about" element={<TheLayout />}>
          <Route index element={<About />} />
        </Route>
        <Route path="/services" element={<TheLayout />}>
          <Route index element={<Services />} />
        </Route>
        <Route path="/strategic-staffing" element={<TheLayout />}>
          <Route index element={<StrategicStaffing />} />
        </Route>
        <Route path="/staff-augmentation" element={<TheLayout />}>
          <Route index element={<StaffAugmentation />} />
        </Route>
        <Route path="/rpo-services" element={<TheLayout />}>
          <Route index element={<RpoServices />} />
        </Route>
        <Route path="/software-development" element={<TheLayout />}>
          <Route index element={<SoftwareDevelopment />} />
        </Route>
        <Route path="/digital-marketing-and-brand-imaging" element={<TheLayout />}>
          <Route index element={<DigitalMarketingBrandImaging />} />
        </Route>
        <Route path="/mobile-app-development" element={<TheLayout />}>
          <Route index element={<MobileAppDevelopment />} />
        </Route>
        <Route path="/project-management" element={<TheLayout />}>
          <Route index element={<ProjectManagement />} />
        </Route>
        <Route path="/website-design-and-development" element={<TheLayout />}>
          <Route index element={<WebsiteDevelopment />} />
        </Route>
        <Route path="/oracle-hcm" element={<TheLayout />}>
          <Route index element={<OracleHRMS />} />
        </Route>
        <Route path="/amazon-web-services" element={<TheLayout />}>
          <Route index element={<AmazonWebServices />} />
        </Route>
        <Route path="/salesforce-consulting-services" element={<TheLayout />}>
          <Route index element={<SalesforceConsultingServices />} />
        </Route>
        <Route path="/data-science-consulting" element={<TheLayout />}>
          <Route index element={<DataScienceConsulting />} />
        </Route>
        <Route path="/industries" element={<TheLayout />}>
          <Route index element={<Industries />} />
        </Route>
        <Route path="/industries/goverment" element={<TheLayout />}>
          <Route index element={<Goverment />} />
        </Route>
        <Route path="/industries/engineering-manufacturing" element={<TheLayout />}>
          <Route index element={<EngineeringManufacturing />} />
        </Route>
        <Route path="/industries/banking-and-finance" element={<TheLayout />}>
          <Route index element={<BankingFinance />} />
        </Route>
        <Route path="/industries/telecommunications" element={<TheLayout />}>
          <Route index element={<Telecommunications />} />
        </Route>
        <Route path="/industries/media-and-entertainment" element={<TheLayout />}>
          <Route index element={<MediaEntertainment />} />
        </Route>
        <Route path="/industries/education-and-elearning" element={<TheLayout />}>
          <Route index element={<EducationElearning />} />
        </Route>
        <Route path="/industries/healthcare-development" element={<TheLayout />}>
          <Route index element={<HealthcareDevelopment />} />
        </Route>
        <Route path="/industries/retail-and-ecommerce" element={<TheLayout />}>
          <Route index element={<RetailEcommerce />} />
        </Route>
        <Route path="/industries/travel-and-hospitality" element={<TheLayout />}>
          <Route index element={<TravelHospitality />} />
        </Route>
        <Route path="/talent-pool" element={<TheLayout />}>
          <Route index element={<HireDevelopers />} />
        </Route>
        <Route path="/career" element={<TheLayout />}>
          <Route index element={<Career />} />
        </Route>
        <Route path="/blog" element={<TheLayout />}>
          <Route index element={<BlogPage />} />
        </Route>
        <Route path="/contact" element={<TheLayout />}>
          <Route index element={<Contact />} />
        </Route>
        <Route path="/privacy-policy" element={<TheLayout />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/terms-conditions" element={<TheLayout />}>
          <Route index element={<TermsConditions />} />
        </Route>
        {/* <Route path="/submit-resume" element={<TheLayout />}>
          <Route index element={<SubmitResume />} />
        </Route> */}

        <Route path="/blog/what-is-staff-augmentation-&-what-are-its-benefits" element={<TheLayout />}>
          <Route index element={<WhatisStaffAugmentationWhatAreItsBenefits />} />
        </Route>

        <Route path="/blog/the-benefits-of-implementing-salesforce-in-your-business" element={<TheLayout />}>
          <Route index element={<TheBenefitsImplementingSalesforceInYourBusiness />} />
        </Route>
        <Route path="/blog/tips-and-tricks-to-develop-a-professional-website" element={<TheLayout />}>
          <Route index element={<TipsAndTricksToDevelopAProfessionalWebsite />} />
        </Route>
        <Route path="/blog/key-steps-in-mobile-app-development-from-idea-to-launch" element={<TheLayout />}>
          <Route index element={<KeyStepsInMobileAppDevelopmentFromIdeaToLaunch />} />
        </Route>
        <Route path="/salesforce-training" element={<TheLayout />}>
          <Route index element={<SalesforceTraining />} />
        </Route>

        <Route path="/404" element={<TheLayout />}>
          <Route index element={<NotFound />} />
        </Route>
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
}

export default App;