import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import * as Yup from "yup";
import "./ContactForm.scss";

const ContactForm = () => {
  const formik = useFormikContext(); // Access Formik context
  // const formikRef = useRef();
  //     console.log(formik)

  //   const { firstName, email, phonenum, categoerySelect, message, file } =
  //     formik.values;
  const [thankYouText, setThankYouText] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const supportedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Your first name is required."),
    phonenum: Yup.string().required("Your phone is required."),
    categoerySelect: Yup.string().required("Please select option."),
    email: Yup.string().email("Invalid email").required("Email is required."),
    message: Yup.string().required("Your message is required."),
    files: Yup.mixed()
      .nullable()
      .test(
        "FILE_FORMAT",
        "Maximun 5 files are allowed",
        // (value) => !value || (value && (typeof value === "object" ? supportedFileTypes.includes(value?.type) : true))
        (value) => {
          if (!value) return true;

          if (value.length > 5) {
            return false;
          }

          //   for (let file of value) {
          //     if (!supportedFileTypes.includes(file.type)) {
          //       return false;
          //     }
          //   }
          return true;
        }
      ),
  });

  const validateFiles = (value) => {
    let error;
    if (value && value.length > 5) {
      console.log("Lafda is here");
      error = "Required";
    }
    return error;
  };

  const sendEmail = async (values) => {
    try {
      const formData = new FormData();
      formData.append("_method", "post");
      formData.append("firstName", values.firstName);
      formData.append("email", values.email);
      formData.append("phonenum", values.phonenum);
      formData.append("categoerySelect", values.categoerySelect);
      formData.append("message", values.message);

      //   formData.append("files", values.files);

      for (let i = 0; i < values.files.length; i++) {
        //   formData.append([`files[${i}]`] , values.files[i])
        formData.append("files", values.files[i]);
      }

      setLoading(true);
      let url = "http://api.vimerseit.com/post-inquiry";
      await axios
        .post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);

          if (res.data && res.status == 200) {
            setThankYouText(true);
          } else {
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <>
        <Formik
          initialValues={{
            firstName: "",
            email: "",
            phonenum: "",
            categoerySelect: "",
            message: "",
            files: null,
          }}
          validationSchema={SignupSchema}
          onSubmit={sendEmail}
        >
          {({
            errors,
            touched,
            field,
            form,
            values,
            handleBlur,
            handleChange,
          }) => (
            <Form
              className="form-resume-submit p-0 box-shadow-none"
              method="POST"
              enctype="multipart/form-data"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      name="firstName"
                      className="form-control"
                      placeholder="Your First Name"
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="firstName"
                      render={(msg) => <div className="error">{msg}</div>}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => <div className="error">{msg}</div>}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      type="tel"
                      name="phonenum"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                    <ErrorMessage
                      name="phonenum"
                      render={(msg) => <div className="error">{msg}</div>}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      as="select"
                      className="form-control custom-select"
                      name="categoerySelect"
                    >
                      <option value="" disabled selected>
                        I am Interested In
                      </option>
                      <option value="Software Development">
                        Software Development
                      </option>
                      <option value="Training">Training</option>
                      <option value="Admin">Admin</option>
                      <option value="Human Resource">Human Resource</option>
                      <option value="Career">Career</option>
                      <option value="Accounts/Finance">Accounts/Finance</option>
                      <option value="Legal/Compliance">Legal/Compliance</option>
                      <option value="Management">Management</option>
                      <option value="Bussiness Enqueries">
                        Bussiness Enqueries
                      </option>
                    </Field>
                    <ErrorMessage
                      name="categoerySelect"
                      render={(msg) => <div className="error">{msg}</div>}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    {/* <Field name="message" className="form-control" placeholder="Message" /> */}
                    <Field
                      as="textarea"
                      name="message"
                      className="form-control"
                      placeholder="Your message"
                    />
                    <ErrorMessage
                      name="message"
                      render={(msg) => <div className="error">{msg}</div>}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <Field name="file" type="file"  validate={validateFiles}>
                      {({
                        field,
                        form: { setFieldValue, setFieldTouched },
                      }) => (
                        <>
                          <input
                            type="file"
                            name="files"
                            id="file"
                            multiple
                            onChange={(event) => {
                              setFieldValue("files", event.currentTarget.files);
                              setFieldTouched("files", true);
                            }}
                            onBlur={field.onBlur}
                            className="file-input form-control"
                            accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx"
                          />

                          <ErrorMessage
                            name="files"
                            render={(msg) => <div className="error">{msg}</div>}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <button type="submit" className="default-btn">
                    {isLoading ? (
                      <div>
                        Loading.... <span></span>
                      </div>
                    ) : (
                      <div>
                        Submit <span></span>
                      </div>
                    )}
                  </button>
                  {!thankYouText ? (
                    <></>
                  ) : (
                    <span className="thanks-para">
                      Thanks for connecting with us. Concern team will contact
                      you soon.
                    </span>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default ContactForm;
