import React from "react";
import { Helmet } from "react-helmet";
import ClientTestiomnails from "../../components/ClientTestiomnails/ClientTestiomnails";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Vimerse InfoTech</title>
        <meta
          name="description"
          content="Welcome to Vimerse InfoTech, a dynamic team of dedicated IT professionals driven by passion and innovation. We specialize in crafting cutting-edge digital products and services that propel customer-centric businesses towards multi-dimensional growth. Join us on our journey to create a brighter digital future together."
        />
        <link rel="canonical" href="https://vimerseit.com/about" />
      </Helmet>

      <section className="repeat-section about-section banner-section">
        <div className="dots-shape-animate">
          <img src="../images/dots-shape-2.png" alt="Shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content">
                <div className="section-title text-left">
                  <div className="subtitle text-red">
                    What Vimerse InfoTech Do
                  </div>
                  <h1>Our Business Offers Top-Notch Ideas </h1>
                </div>
                <p>
                  Vimerse InfoTech is an IT Solution provider that works round
                  the clock to support businesses with their IT infra
                  requirements. We can be your next tech partner to craft a
                  solution to make your tasks automated and effortless. Our
                  talented experts know what it takes to develop impeccable IT
                  solutions.
                </p>
                <p>
                  Our solutions help businesses improve efficiency,
                  productivity, customer service, and security and reduce costs.
                  You can have solutions for Strategic Staffing, Staff
                  Augmentation, RPO Services and more with us. Vimerse InfoTech
                  also provides technical consultation related to Cloud
                  Services, Oracle HRMS, AWS, Salesforce, Data Science, and
                  others. Our key objective is to produce innovative and
                  future-ready solutions to let our clients overcome the top
                  challenges they face with their businesses.
                </p>
                <p>
                  It is a fantastic journey; we started in 2011 (earlier Exim),
                  and in 2018 we started our new chapter with a new name called
                  Vimerse InfoTech Inc.
                </p>

                <div className="company-stats">
                  <div className="row">
                    <div className="col-12">
                      <div className="rebranding-image">
                        {/* <img src='../images/rebranding.png' alt="Rebranding" /> */}
                        <img
                          src="../images/rebranding-2.png"
                          alt="Rebranding"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='row'>
										<div className='col-md-6'>
											<div className='stats-bx'>
												<div className='count-stats'>
													350+
												</div>
												<p>Success Project</p>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='stats-bx'>
												<div className='count-stats'>
													4.7+
												</div>
												<p>Client Rating</p>
											</div>
										</div>
									</div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="about-image">
                <img src="../images/our-company-1.png" alt="Vimerse InfoTech" />
                <div className="about-stats">
                  <div className="years-count">14+</div>
                  <span>Years Experience</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="our-company-media border-radius">
                <img src="../images/our-company-2.png" alt="Vimerse InfoTech" />
                <div className="horizental-bar bg-blue"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='repeat-section bg-f2f2f2 founder-section'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-4 col-md-12'>
							<div className='founder-detail'>
								<div className='founder-image'>
									<img src="../images/founder.jpg" />
								</div>
								<div className='founder-name'>
									<h2>Sajjan Singh</h2>
									<p>Founder | President | CEO <br />of Vimerse InfoTech Inc</p>
									<a href='https://www.linkedin.com/in/sajjansingh' target="_blank">
										<img src='../images/icons/linkedin-icon.svg' />
									</a>
									<div className='linkedin-arrow-icon'>
										<img src='../images/icons/linkedin-arrow-icon.svg' />
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-8 col-md-12'>
							<div className='company-descripition '>
								<h2>An Intention Behind Establishing Vimerse InfoTech Inc</h2>
								<p>
									Having an experience of over a decade of full-cycle recruitment, staffing process, and human resource management, our CEO, Sajjan Singh, founded Vimerse InfoTech Inc. He specialises in IT practices, hiring & talent management, recruitment process improvement, business strategies & implementation, business planning, compliance management and more.
								</p>
								<p>
									The company started with the sole objective: to empower clients with out-of-the-box IT solutions aligned to their business needs and continuously deliver seamless IT solutions to different industries.
								</p>
								<p>
									Our rooted knowledge of diverse industry domains made us an exceptional jack of all trades. We provide personalised solutions to industries or businesses based on extensive requirement analysis. We utilise our full potential and knowledge to make a solution outstanding. Including a result-driven approach, we also care about each aspect to make the client’s investment worthwhile.
								</p>
								<p>
									We offer a wide range of services & solutions that fits well as per needs. Additionally, cost-effectiveness, agile methodology, and time-saving are key advantages of partnering with us.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}

      <section className="repeat-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 d-flex">
              <div className="box-design">
                <div className="icon">
                  <img src="../images/icons/vision-icon.svg" />
                </div>
                <h2>Vision</h2>
                <p>
                  Our vision is to be the first preference as a tech partner for
                  businesses where they can find sustainable IT solutions that
                  help in constant growth and unlocking the full potential. We
                  want to create an IT-based ecosystem where all-sized
                  businesses must get what they need through consultation or
                  solutions.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex">
              <div className="box-design">
                <div className="icon">
                  <img src="../images/icons/mission-icon.svg" />
                </div>
                <h2>Mission</h2>
                <p>
                  Our mission is to provide the latest tech-based IT solutions
                  that help businesses for seamless operations, customer
                  satisfaction, and better resource management with
                  cost-effectiveness. Technology is a powerful tool for growing
                  business faster; by considering it, we have designed and
                  developed our service array to cater to the demand of global
                  businesses and industries.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="repeat-section bg-f2f2f2 our-approch-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 ">
              <div className="section-title text-left max-width-unset mb-3">
                <div className="subtitle text-red">OUR APPROCH</div>
                <h2>Client-Centric Solutions Always Comes First For Us</h2>
              </div>
              <p>
                Our client-centric thought process fuels us for innovation while
                developing IT solutions for enterprises and businesses. Our
                prime motto is to develop solutions, so our clients must find
                the best fit for their needs. Whether on-shore, off-shore, or
                nearshore, we utilise the full potential of our resources and
                skills to add value to the client’s business via technology.
              </p>
              <p>
                From developing core solutions to automation, whatever is
                required by us, you will have access to our skills & expertise
                to produce something fruitful and aligned with your business. It
                gives us the thrill of working on new challenges daily, making
                us one of the preferred partners for all tech needs.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">
              <div className="image-radius-design">
                <img src="../images/our-approch.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="repeat-section our-success-section">
        <div className="container">
          <div className="success-bg">
            <div className="section-title max-width-unset">
              <div className="subtitle text-red">What We Have Achieved </div>
              <h2 className="text-white">
                The Outcomes We Have Through Out Our Journey To Date{" "}
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="success-bx">
                  <h3>180+</h3>
                  <p>Projects Launched </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="success-bx">
                  <h3>14+</h3>
                  <p>Years of Experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="success-bx">
                  <h3>75+</h3>
                  <p>Dedicated Developers</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="success-bx">
                  <h3>90%</h3>
                  <p>Client Retention</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='repeat-section bg-f2f2f2 our-team-section'>
				<div className='container'>
					<div className='section-title max-width-unset'>
						<div className='subtitle text-red'>Meet Our Team</div>
						<h2 className=''>Know Our Team Whose Dedication Makes Us Deliver Right Solutions</h2>
					</div>
					<div className='row'>
						<div className='col-lg-3 col-md-6 d-flex'>
							<div className='team-bx'>
								<img src='../images/team1.jpg' alt="team" />
								<div className='team-details'>
									<h3>Sudra Meni</h3>
									<p>Business Head</p>
								</div>
								<div className='socials-icon'>
									<a href='https://in.linkedin.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
											<path fill="Currentcolor" d="M5.6,25H.413V8.309H5.6ZM3,6.032A3.022,3.022,0,1,1,6,3,3.027,3.027,0,0,1,3,6.032ZM24.994,25H19.823V16.874c0-1.936-.039-4.42-2.695-4.42-2.695,0-3.108,2.1-3.108,4.28V25H8.843V8.309h4.971v2.277h.073a5.446,5.446,0,0,1,4.9-2.7c5.246,0,6.21,3.454,6.21,7.941V25Z" />
										</svg>
									</a>
									<a href='https://www.facebook.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="13.39" height="25" viewBox="0 0 13.39 25">
											<path fill="Currentcolor" d="M12.577,14.817l.694-4.524H8.93V7.357a2.262,2.262,0,0,1,2.551-2.444h1.974V1.06a24.068,24.068,0,0,0-3.5-.306c-3.575,0-5.912,2.167-5.912,6.09v3.448H.064v4.524H4.039V25.755H8.93V14.817Z" transform="translate(-0.064 -0.755)" />
										</svg>
									</a>
									<a href='https://twitter.com/i/flow/login' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.305" viewBox="0 0 25 20.305">
											<path fill="Currentcolor" d="M22.69,5.129c.016.222.016.444.016.666A14.478,14.478,0,0,1,8.127,20.374a14.479,14.479,0,0,1-7.868-2.3,10.613,10.613,0,0,0,1.237.063,10.261,10.261,0,0,0,6.361-2.189,5.133,5.133,0,0,1-4.791-3.553,6.456,6.456,0,0,0,.968.079A5.423,5.423,0,0,0,5.383,12.3,5.125,5.125,0,0,1,1.274,7.271V7.207a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,2,1,14.564,14.564,0,0,0,12.569,6.366a5.784,5.784,0,0,1-.127-1.174,5.129,5.129,0,0,1,8.867-3.506A10.087,10.087,0,0,0,24.561.45a5.11,5.11,0,0,1-2.252,2.824,10.271,10.271,0,0,0,2.95-.793A11.016,11.016,0,0,1,22.69,5.129Z" transform="translate(-0.259 -0.069)" />
										</svg>
									</a>
								</div>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 d-flex'>
							<div className='team-bx'>
								<img src='../images/team1.jpg' alt="team" />
								<div className='team-details'>
									<h3>Sudra Meni</h3>
									<p>Business Head</p>
								</div>
								<div className='socials-icon'>
									<a href='https://in.linkedin.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
											<path fill="Currentcolor" d="M5.6,25H.413V8.309H5.6ZM3,6.032A3.022,3.022,0,1,1,6,3,3.027,3.027,0,0,1,3,6.032ZM24.994,25H19.823V16.874c0-1.936-.039-4.42-2.695-4.42-2.695,0-3.108,2.1-3.108,4.28V25H8.843V8.309h4.971v2.277h.073a5.446,5.446,0,0,1,4.9-2.7c5.246,0,6.21,3.454,6.21,7.941V25Z" />
										</svg>
									</a>
									<a href='https://www.facebook.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="13.39" height="25" viewBox="0 0 13.39 25">
											<path fill="Currentcolor" d="M12.577,14.817l.694-4.524H8.93V7.357a2.262,2.262,0,0,1,2.551-2.444h1.974V1.06a24.068,24.068,0,0,0-3.5-.306c-3.575,0-5.912,2.167-5.912,6.09v3.448H.064v4.524H4.039V25.755H8.93V14.817Z" transform="translate(-0.064 -0.755)" />
										</svg>
									</a>
									<a href='https://twitter.com/i/flow/login' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.305" viewBox="0 0 25 20.305">
											<path fill="Currentcolor" d="M22.69,5.129c.016.222.016.444.016.666A14.478,14.478,0,0,1,8.127,20.374a14.479,14.479,0,0,1-7.868-2.3,10.613,10.613,0,0,0,1.237.063,10.261,10.261,0,0,0,6.361-2.189,5.133,5.133,0,0,1-4.791-3.553,6.456,6.456,0,0,0,.968.079A5.423,5.423,0,0,0,5.383,12.3,5.125,5.125,0,0,1,1.274,7.271V7.207a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,2,1,14.564,14.564,0,0,0,12.569,6.366a5.784,5.784,0,0,1-.127-1.174,5.129,5.129,0,0,1,8.867-3.506A10.087,10.087,0,0,0,24.561.45a5.11,5.11,0,0,1-2.252,2.824,10.271,10.271,0,0,0,2.95-.793A11.016,11.016,0,0,1,22.69,5.129Z" transform="translate(-0.259 -0.069)" />
										</svg>
									</a>
								</div>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 d-flex'>
							<div className='team-bx'>
								<img src='../images/team1.jpg' alt="team" />
								<div className='team-details'>
									<h3>Sudra Meni</h3>
									<p>Business Head</p>
								</div>
								<div className='socials-icon'>
									<a href='https://in.linkedin.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
											<path fill="Currentcolor" d="M5.6,25H.413V8.309H5.6ZM3,6.032A3.022,3.022,0,1,1,6,3,3.027,3.027,0,0,1,3,6.032ZM24.994,25H19.823V16.874c0-1.936-.039-4.42-2.695-4.42-2.695,0-3.108,2.1-3.108,4.28V25H8.843V8.309h4.971v2.277h.073a5.446,5.446,0,0,1,4.9-2.7c5.246,0,6.21,3.454,6.21,7.941V25Z" />
										</svg>
									</a>
									<a href='https://www.facebook.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="13.39" height="25" viewBox="0 0 13.39 25">
											<path fill="Currentcolor" d="M12.577,14.817l.694-4.524H8.93V7.357a2.262,2.262,0,0,1,2.551-2.444h1.974V1.06a24.068,24.068,0,0,0-3.5-.306c-3.575,0-5.912,2.167-5.912,6.09v3.448H.064v4.524H4.039V25.755H8.93V14.817Z" transform="translate(-0.064 -0.755)" />
										</svg>
									</a>
									<a href='https://twitter.com/i/flow/login' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.305" viewBox="0 0 25 20.305">
											<path fill="Currentcolor" d="M22.69,5.129c.016.222.016.444.016.666A14.478,14.478,0,0,1,8.127,20.374a14.479,14.479,0,0,1-7.868-2.3,10.613,10.613,0,0,0,1.237.063,10.261,10.261,0,0,0,6.361-2.189,5.133,5.133,0,0,1-4.791-3.553,6.456,6.456,0,0,0,.968.079A5.423,5.423,0,0,0,5.383,12.3,5.125,5.125,0,0,1,1.274,7.271V7.207a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,2,1,14.564,14.564,0,0,0,12.569,6.366a5.784,5.784,0,0,1-.127-1.174,5.129,5.129,0,0,1,8.867-3.506A10.087,10.087,0,0,0,24.561.45a5.11,5.11,0,0,1-2.252,2.824,10.271,10.271,0,0,0,2.95-.793A11.016,11.016,0,0,1,22.69,5.129Z" transform="translate(-0.259 -0.069)" />
										</svg>
									</a>
								</div>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 d-flex'>
							<div className='team-bx'>
								<img src='../images/team1.jpg' alt="team" />
								<div className='team-details'>
									<h3>Sudra Meni</h3>
									<p>Business Head</p>
								</div>
								<div className='socials-icon'>
									<a href='https://in.linkedin.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
											<path fill="Currentcolor" d="M5.6,25H.413V8.309H5.6ZM3,6.032A3.022,3.022,0,1,1,6,3,3.027,3.027,0,0,1,3,6.032ZM24.994,25H19.823V16.874c0-1.936-.039-4.42-2.695-4.42-2.695,0-3.108,2.1-3.108,4.28V25H8.843V8.309h4.971v2.277h.073a5.446,5.446,0,0,1,4.9-2.7c5.246,0,6.21,3.454,6.21,7.941V25Z" />
										</svg>
									</a>
									<a href='https://www.facebook.com/' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="13.39" height="25" viewBox="0 0 13.39 25">
											<path fill="Currentcolor" d="M12.577,14.817l.694-4.524H8.93V7.357a2.262,2.262,0,0,1,2.551-2.444h1.974V1.06a24.068,24.068,0,0,0-3.5-.306c-3.575,0-5.912,2.167-5.912,6.09v3.448H.064v4.524H4.039V25.755H8.93V14.817Z" transform="translate(-0.064 -0.755)" />
										</svg>
									</a>
									<a href='https://twitter.com/i/flow/login' target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.305" viewBox="0 0 25 20.305">
											<path fill="Currentcolor" d="M22.69,5.129c.016.222.016.444.016.666A14.478,14.478,0,0,1,8.127,20.374a14.479,14.479,0,0,1-7.868-2.3,10.613,10.613,0,0,0,1.237.063,10.261,10.261,0,0,0,6.361-2.189,5.133,5.133,0,0,1-4.791-3.553,6.456,6.456,0,0,0,.968.079A5.423,5.423,0,0,0,5.383,12.3,5.125,5.125,0,0,1,1.274,7.271V7.207a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,2,1,14.564,14.564,0,0,0,12.569,6.366a5.784,5.784,0,0,1-.127-1.174,5.129,5.129,0,0,1,8.867-3.506A10.087,10.087,0,0,0,24.561.45a5.11,5.11,0,0,1-2.252,2.824,10.271,10.271,0,0,0,2.95-.793A11.016,11.016,0,0,1,22.69,5.129Z" transform="translate(-0.259 -0.069)" />
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

      <ClientTestiomnails />
    </>
  );
};

export default About;
