import React from "react";
import { Link } from "react-router-dom";
import MainBanner from "../../components/HomeComponents/MainBanner";
// import './Home.scss';
import WhyChoose from "../../components/WhyChoose/WhyChoose";
import ClientTestiomnails from "../../components/ClientTestiomnails/ClientTestiomnails";
import BlogPost from "../../components/BlogData/BlogPost";
import Portfollio from "../../components/Portfollio/Portfollio";
import CommanServices from "../../components/CommanServices/CommanServices";
import ClientLogos from "../../components/ClientLogos/ClientLogos";
import TechnologyTechStack from "../../components/TechnologyTechStack/TechnologyTechStack";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Vimerse InfoTech</title>
        <meta
          name="description"
          content="Vimerse InfoTech leading web & mobile app development company, powered by cutting-edge tech to help you achieve your business goals."
        />
        <meta
          name="keywords"
          content="Top Web and Mobile App Development Company, web development company, app development company, web and mobile app development company, software development company, best it company, Web Design Company, web and mobile app development agency"
        />
        <link rel="canonical" href="https://vimerseit.com/" />
      </Helmet>

      <MainBanner />

      <section className="repeat-section about-section">
        <div className="dots-shape-animate">
          <img src="../images/dots-shape-2.png" alt="Shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="about-image">
                <img src="../images/our-company-1.png" alt="Vimerse InfoTech" />
                <div className="about-stats">
                  <div className="years-count">14+</div>
                  <span>Years Experience</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="our-company-media border-radius">
                <img src="../images/our-company-2.png" alt="Vimerse InfoTech" />
                <div className="horizental-bar bg-blue"></div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="about-content">
                <div className="section-title text-left">
                  <div className="subtitle text-red">Who We Are?</div>
                  <h2>Vimerse InfoTech For Seamless IT Solutions</h2>
                </div>
                <p>
                  Our commitment fuels us to deliver the solutions that help
                  businesses meet their current and future goals. We have a team
                  of experienced professionals having rooted knowledge of
                  diverse industry domains with a proven track record. They can
                  help you to implement innovative IT solutions tailored to your
                  business. We ensure scalability, flexibility, and
                  sustainability with our IT services and solutions.
                </p>
                <p>
                  Let’s partner to unleash the full potential of your business!
                </p>

                <div className="company-stats">
                  <div className="row">
                    <div className="col-md-6 col-6 d-flex">
                      <div className="stats-bx w-100">
                        <div className="count-stats">350+</div>
                        <p>Success Project</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-6 d-flex">
                      <div className="stats-bx w-100">
                        <div className="count-stats">4.7+</div>
                        <p>Client Rating</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CommanServices />

      {/* <section className='repeat-section services-section-2'>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Services</h2>
						<p>Experienced Consultants Delivering On A Broad Set Of Tech Requirements.</p>
					</div>

					<div className='row'>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Strategic Staffing</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Staff Augmentation</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>RPO Services</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Software Development</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Accounting &Payroll Solutions</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>IT Infrastructure</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Website Design</h3>
							</Link>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<Link to='#' className='services-bx'>
								<div className='icon'>
									<img src='../images/icons/strategic-staffing.svg' width="60px" />
								</div>
								<h3>Website Development</h3>
							</Link>
						</div>
					</div>
				</div>
			</section> */}

      <section className="repeat-section industry-section bg-white">
        <div className="container">
          <div className="section-title">
            <h2>Explore Our Industry Expertise</h2>
            <p>
              Here are some glimpses of our industry expertise, so explore and
              find out how we can help you with your suitable needs.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/government-industry.jpg"
                    alt="Government"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Government</h3>
                  <p>
                    We are extensively experienced in providing technical
                    assistance to the governing bodies that allow us to design,
                    develop, and deploy solutions to the citizens. Vimerse
                    InfoTech can assist you with high transparency and effective
                    citizen services.{" "}
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/goverment"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/information-technology.jpg' alt='Information Technology' className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Information Technology </h3>
									<p>Information Technology is an integral part of any business as it offers multiple advantages to leverage for growth. We can provide you with the right team for it to implement the latest IT solutions. </p>
									<div className='readmore-btn'>
										<Link to="/information-technology" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/engineering-manufacturing.jpg"
                    alt="Engineering Manufacturing"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Engineering Manufacturing</h3>
                  <p>
                    The manufacturing industry can overcome several challenges
                    with the help of technology and solutions. If you are
                    involved in manufacturing, we can help you streamline all
                    your ops via providing domain based IT solutions.
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/engineering-manufacturing"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/banking-and-finance.jpg"
                    alt="Banking & Finance"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Banking & Finance</h3>
                  <p>
                    Banking and finance is one of the emerging sectors where
                    innovation is still needed. Our industry specialists can
                    provide solutions that offer better customer experience and
                    service quality, including following compliance.{" "}
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/banking-and-finance"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/retail-and-ecommerce.jpg"
                    alt="Retail & eCommerce"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Retail & eCommerce</h3>
                  <p>
                    Retail & ecommerce tend to cater to the demand of a large
                    customer base. So for the best shopping experience and
                    flawless services, a solution can help a lot. We have
                    expertise in it, and our experts can develop it based on all
                    your needs.
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/retail-and-ecommerce"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/healthcare.jpg"
                    alt="Healthcare"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Healthcare</h3>
                  <p>
                    Patient-centric solutions, seamless workflows, and data
                    privacy are the key requirements of the current healthcare
                    industry. With us, you will have a seamless experience with
                    modern healthcare solutions covering all needs.{" "}
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/healthcare-development"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/telecommunications.jpg"
                    alt="Telecommunications"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Telecommunications</h3>
                  <p>
                    Create a personalized telecommunication experience via
                    accelerating network automation and user-oriented IT
                    solutions. Our experts have rooted knowledge in implementing
                    result-oriented telecommunication solutions.{" "}
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/telecommunications"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/education-eLearning.jpg"
                    alt="Education & eLearning"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Education & eLearning</h3>
                  <p>
                    Education & eLearning is going from a revolutionary phase,
                    and there is ample need for exceptional solutions. We can
                    help you to make the teaching-learning process innovative
                    with our IT expertise, where you can easily map outcomes.{" "}
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/education-and-elearning"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/travel-hospitality.jpg"
                    alt="Travel & Hospitality"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Travel & Hospitality</h3>
                  <p>
                    Give your customers the best experience of your travel
                    services with innovative solutions. Vimerse InfoTech Inc is
                    a leading services and solutions provider to the travel
                    industry, where you will have some key advantages such as
                    cost-effectiveness and scalability.
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/travel-and-hospitality"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/food-restaurants.jpg' alt="Food & Restaurants" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Food & Restaurants</h3>
									<p>Lorem ipsum</p>
									<div className='readmore-btn'>
										<Link to="/industries/food-and-restaurants" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
              <div className="industry-bx">
                <div className="image">
                  <img
                    src="../images/industry/media-entertainment.jpg"
                    alt="Media & Entertainment"
                    className="img-fluid"
                  />
                </div>
                <div className="industry-detail">
                  <h3>Media & Entertainment</h3>
                  <p>
                    Media & entertainment industry is one of the dynamic
                    industries where content creation, management, and
                    distribution are its key tasks. We can provide you with a
                    team of experts to ease your media business tasks.
                  </p>
                  <div className="readmore-btn">
                    <Link
                      to="/industries/media-and-entertainment"
                      className="default-btn btn-primary"
                    >
                      Read More <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhyChoose />

      <ClientTestiomnails />

      <BlogPost />

      <Portfollio />

      <ClientLogos />

      <TechnologyTechStack />
    </>
  );
};

export default Home;
